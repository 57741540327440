import styled from 'styled-components';

export const Container = styled.div``;

export const Label = styled.h4`
  margin: 20px 0 20px 0;
  font-weight: 600;
  font-size: 20px;
`;

export const TubList = styled.ul`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  list-style-type: none;
  padding: 0;

  @media (max-width: 500px) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 400px;
    margin: 0 auto;
  }
`;

export const TubListItem = styled.li`
  display: block;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  padding: 0;
  cursor: pointer;

  &.active > div {
    opacity: 1;
  }

  &.current > div {
    border: 1px solid #e26447;
    -webkit-box-shadow: 0 0 5px 0 #e26447;
    box-shadow: 0 0 5px 0 #e26447;
    opacity: 1;
  }

  @media (max-width: 500px) {
    display: block;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    padding: 5px;
  }
`;

export const TubListItemContainer = styled.div`
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  text-align: center;
  padding: 12px 0;
  width: 124px;
  font-size: 16px;
  line-height: 19px;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 1071px) {
    width: 110px;
  }

  @media (max-width: 1000px) {
    width: 150px;
  }

  @media (max-width: 1000px) {
    width: 150px;
  }

  @media (max-width: 510px) {
    width: 150px;
  }

  @media (max-width: 320px) {
    width: 130px;
  }

  & img {
    height: 38px;
    display: block;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    margin: 0 auto;
  }
  & span {
    font-weight: 600;
    margin-top: 7px;
    display: block;
    white-space: nowrap;
  }
`;

export const PackageContainer = styled.div`
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  min-height: 100px;
`;

export const AddToCartButton = styled.button`
    position: relative;
    display: block;
    text-align: center;
    font-size: 18px;
    background: #e26447;
    width: 100%;
    padding: 18px 0;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    line-height: 18px;
    vertical-align: middle;
    -webkit-box-shadow: 0 4px 0 #a54e32;
    box-shadow: 0 4px 0 #a54e32;
    -webkit-transition: all .2s;
    transition: all .2s;
    border: 0;
    cursor: pointer;

    &:active, &:focus {
        border: 0;
    }

    &:hover {
        background: #a54e32;
        & > span {
            top: 2px;
        }
        &:after {
            visibility: visible;
            right: 15px;
        }
    }
    & span {
        position: relative;
        width: 130px;
        display: block;
        margin: auto;
        text-transform: uppercase;
        &:before {
            content: " ";
            background-image: url('/images/cart.svg');
            width: 25px;
            height: 20px;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: -25px;
        }
    }

    &:after {
        content: " ";
        display: block;
        background-image: url("/images/fa-chevron-right2.svg");
        background-position: center center;
        background-repeat: no-repeat;
        width: 15px;
        height: 20px;
        right: 30px;
        top: 17px;
        font-weight: 100;
        position: absolute;
        visibility: hidden;
        color: #fff;
        font-size: 18px;
        -webkit-transition: .1s linear;
        transition: .1s linear;
    }

    &.processing {

        background: #a54e32;
        & > span {
            top: 0px;
        }
        &:after {
            visibility: hidden;
        }
        
        &:before {
            content: "";
            height: 22px;
            width: 100%;
            background-image: url("/images/three-dots.svg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
        }
        & > span {
            display: none;
        }
    }

    ${({ pageDesign }) => {
      if (pageDesign === `design2`) {
        return `
          background-color: #BC2E3E;
          -webkit-box-shadow: 0 4px 0 #961423;
          box-shadow: 0 4px 0 #961423;
          &:hover {
            background-color: #961423;
          }
          & > span {
            font-size: 22px;
            font-weight: bold;
            text-transform: capitalize;
            width: 150px;
          }
          `;
      }
    }}
}
`;

export const BumpOfferWrapper = styled.div`
  width: 100%;
  margin: 15px 0;
`;

export const BumpOffer = styled.div`
  padding: 10px 15px;
  margin: 20px 0;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: relative;
  min-height: 80px;

  &.active {
    border: 1px solid #e26447;
    -webkit-box-shadow: 0 0 5px 0 #e26447;
    box-shadow: 0 0 5px 0 #e26447;
  }

  &:after {
    content: ' ';
    ${({ pageDesign }) => {
      if (pageDesign === `design2`) {
        return `
             background-image: url("/images/downsell-ribbon2.svg");
              `;
      } else {
        return `
             background-image: url("/images/downsell-ribbon.svg");
              `;
      }
    }}
    background-repeat: no-repeat;
    display: block;
    width: 51px;
    height: 56px;
    position: absolute;
    top: -8px;
    right: -10px;

    @media (max-width: 500px) {
      background-size: 90%;
      width: 41px;
      height: 45px;
    }
  }

  &.active:after {
    -ms-transform: rotate(8deg);
    transform: rotate(8deg);
  }
`;

export const BumpOfferLabel = styled.label`
  position: relative;

  & input:checked + div:before {
    position: absolute;
    content: '';
    top: 23px;
    left: -1px;
    width: 8px;
    height: 18px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    z-index: 1;
    -webkit-transform: rotate(37deg);
    transform: rotate(37deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;

    @media (max-width: 768px) {
      top: 18px;
    }
  }

  & input:checked + div:after {
    position: absolute;
    content: '';
    top: 25px;
    left: 0;
    border: 2px solid #d26546;
    background-color: #d26546;
    z-index: 0;
    -webkit-transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
      height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
    transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
      height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;

    @media (max-width: 768px) {
      top: 20px;
    }
  }
`;

export const BumpOfferImage = styled.img`
  max-width: 56px !important;
  float: left;
  max-height: 60px;
`;

export const BumpOfferCheckbox = styled.input`
  display: none;
`;

export const BumpOfferContent = styled.div`
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;

  &:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 3px solid transparent;
    left: 6px;
    top: 25px;
    -webkit-transform: rotate(37deg);
    transform: rotate(37deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;

    @media (max-width: 768px) {
      top: 20px;
    }
  }
  &:after {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: 1px solid #5a5a5a;
    top: 25px;
    left: 0;
    z-index: 0;

    @media (max-width: 768px) {
      top: 20px;
    }
  }
`;

export const BumpOfferContentText = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 14px;
  margin: 0 !important;
  line-height: 1.6;
  padding-right: 20px;
  text-align: center;
  min-height: 60px;
  ${({ pageDesign }) => {
    if (pageDesign === `design2`) {
      return `
          text-align: left;
          padding-right: 35px;
          padding-left: 20px;
          `;
    }
  }}

  & > span {
    color: #e26447;
  }

  @media (max-width: 500px) {
    font-size: 12px;

    & > span {
      display: inline;
    }
  }
`;

export const Tabs = styled.div`
  display: flex;
`;

export const TabItem = styled.div`
  flex: 0 0 50%;
  background: #f4f4f4;

  & label {
    cursor: pointer;
    display: block;
    padding: 8px;

    & > input[type='radio'] {
      margin: 0;
    }

    @media (max-width: 320px) {
      padding-right: 0px;
    }
  }

  &.active {
    background: #fff;
  }
`;

export const TabItemTitle = styled.span`
  font-size: 17px;
  margin-left: 5px;
  font-weight: 600;

  & > span {
    font-size: 16px;
    color: #e26447;
  }

  @media (max-width: 500px) {
    font-size: 14px;
    & > span {
      font-size: 12px;
    }
  }

  @media (max-width: 375px) {
    font-size: 12px;
    & > span {
      font-size: 12px;
    }
  }

  @media (max-width: 320px) {
    font-size: 12px;
    & > span {
      font-size: 12px;
      letter-spacing: -1px;
    }
  }
`;

export const TabContent = styled.div`
  padding: 15px;

  @media (max-width: 500px) {
    padding: 13px;
  }
`;

export const SubsPlan = styled.div`
  display: flex;
  @media (max-width: 500px) {
    display: block;
    padding: 10px;
  }
`;

export const SubsPlanItem = styled.div`
  flex: 0 0 50%;
  & h3 {
    font-size: 14px;
    font-weight: 600;
    color: #e26447;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    & > i {
      color: #fff;
      font-size: 10px;
      background-color: #e26447;
      border-radius: 100%;
      padding: 3px 4px;
      margin-right: 5px;
      display: inline-block;
      width: 17px;
      height: 17px;

      &.icon.icon-pencil::after {
        content: ' ';
        display: block;
        width: 8px;
        height: 10px;
        background-image: url('/images/fa-pencil.svg');
        background-repeat: no-repeat;
        background-position: center;
      }

      &.icon.icon-close::after {
        content: ' ';
        display: block;
        width: 8px;
        height: 10px;
        background-image: url('/images/fa-close.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
  & p {
    margin: 0 0 0 10px;
    font-size: 12px;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const Frequency = styled.div`
  & p {
    margin: 0;
  }
  & select {
    display: block;
    width: 100%;
    color: #000;
    padding: 8px 16px;
    font-size: 16px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    background-color: #fff;
  }
`;

export const Price = styled.div`
  color: #e26447;
  font-size: 18px;
  display: block;
  white-space: nowrap;

  & > span {
    color: #213f4e;
    text-decoration: line-through;
  }
`;

export const OnetimeContent = styled.div`
  padding-left: 40px;
  position: relative;
  &:before {
    content: '';
    background-image: url('/images/indicator-arrow.svg');
    background-repeat: no-repeat;
    height: 100px;
    width: 50px;
    position: absolute;
    top: -10px;
    left: 0;
  }
  & p {
    margin: 5px 0 0 0;
  }
`;

export const SubPopup = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: #e26447;
`;
